import '../styles/globals.css'
import '@jetprivilege/web/dist/css/theme.css';
import 'react-day-picker/lib/style.css';
import React from 'react'
import Head from 'next/head'

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Book Hotels Online in India | Budget & Luxury Hotels at Best Price | InterMiles</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Hotels in India - Book cheap, budget and luxury hotels online at great prices with Intermiles. Search from budget hotels to luxury suites and save with Intermiles online booking." />
        <meta name="keywords" content="budget hotels, cheap hotels, hotel booking, luxury hotels, discount hotels, hotel reservation, book hotel online, hotels, hotels, accommodation deals, hotel deals, best hotel deals, earn InterMiles, InterMiles hotels booking" />
        <base href="/" />
        <meta name="theme-color" content="#000000" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      </Head>
      <Component {...pageProps} />
    </>)
}

export default MyApp
